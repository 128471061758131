import { render, staticRenderFns } from "./PointLabel.vue?vue&type=template&id=82647ee4&scoped=true&functional=true"
var script = {}
import style0 from "./PointLabel.vue?vue&type=style&index=0&id=82647ee4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "82647ee4",
  null
  
)

export default component.exports