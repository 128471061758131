<template>
  <el-drawer
    title="执行日志"
    :visible.sync="show"
    direction="rtl"
    @close="$emit('close')"
    size="800px"
  >
    <div class="logDrawer">
      <el-timeline
        v-show="logs.length > 0"
      >
        <el-timeline-item
          v-for="(log, index) in logs"
          :key="index"
          :timestamp="log.createTime"
          hide-timestamp
        >
          <div class="pr">
            <div class="pa showTime">{{ log.createTime }}</div>
            <div>{{ log.stepInfo }}</div>
            <div
              v-if="log.failReason"
              class="bg-f2 failReason p-10"
            >
              {{ log.failReason }}
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-drawer>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    logs: Array,
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.logDrawer {
  padding-left: 200px;
  box-sizing: content-box;
  margin-top: 10px;
  .showTime {
    width: 245px;
    text-align: right;
    padding-right: 100px;
    left: -200px;
  }
  .failReason {
    line-height: 1.5;
  }
}
</style>
