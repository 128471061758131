<template>
  <el-form label-width="100px">
    <el-form-item label="文件模版">
      <div>
        <el-button
          type="text"
          @click="downFile"
          >下载模板</el-button
        >
        <p class="color-danger lh1">{{ `1、压缩包名称：产品ID#自有填写部分.zip${fileUrlList[activeTab].name}` }}</p>
        <p class="color-danger lh1">2、压缩包文件目录不可调整；</p>
      </div>
    </el-form-item>
    <el-form-item label="广告资料">
      <el-upload
        class="upload-demo"
        action="#"
        :auto-upload="false"
        ref="upload"
        :on-change="handleChange"
        :before-remove="handleChange"
        :file-list="fileList"
        accept=".zip"
        :multiple="activeTab == 1"
      >
        <el-button type="primary">点击上传</el-button>
        <div
          slot="file"
          slot-scope="{ file }"
        >
          <div>
            {{ file.name
            }}<span
              style="color: red"
              v-if="file.error"
            >
              ({{ file.error }})
            </span>
            <i
              class="el-icon-close"
              @click="remove(file)"
            ></i>
          </div>
        </div>
      </el-upload>
    </el-form-item>
  </el-form>
</template>
<script>
import { uploadZipFiles, checkZipName } from '@/api/addGoods';
export default {
  props: {
    activeTab: {
      type: Number,
      default: 0, //0是facebook 1是snapchat
    },
  },
  data() {
    return {
      fileList: [],
      fileUrlList: [
        {
          activeTab: 0,
          platform: 'facebook',
          name: '，例如324532#鞋子.zip',
          url: 'https://gamsad.giikin.cn/ads/tmpl/538920%23%E9%AB%98%E6%A1%A3%E4%BC%91%E9%97%B2%E7%94%B7%E5%A3%AB%E7%9F%AD%E8%A2%96-fb-new.zip',
        },
        {
          activeTab: 1,
          platform: 'snapchat',
          name: '，例如538920#高档休闲男士短袖.zip',
          url: 'https://gamsad.giikin.cn/ads/tmpl/538920%23%E9%AB%98%E6%A1%A3%E4%BC%91%E9%97%B2%E7%94%B7%E5%A3%AB%E7%9F%AD%E8%A2%96-SNAP.zip',
        },
      ],
      isRepeat: true, // 用来记录上传的文件是否重复
    };
  },
  methods: {
    // 上传前验证
    beforeUpload(file) {
      console.log('beforeUpload', file);
      if (!file.type.includes('zip')) {
        this.$message.warning('请上传正确的压缩包格式');
        return Promise.reject();
      }
    },
    // 上传
    uploadZip(params) {
      let formData = new FormData();
      let canUpload = true;
      this.fileList.forEach((file) => {
        formData.append('file', file.raw);
        if (file.error) {
          canUpload = false;
        }
      });
      if (this.activeTab == 1) {
        console.log(params);
        formData.append('platform', 'snapchat');
        formData.append('snapchatAutoUploadJson', JSON.stringify(params));
      }
      if (this.activeTab == 0) {
        formData.append('platform', 'facebook');
      }

      if (!canUpload) return;
      this.$emit('uploadLoading', true);
      this.$showLoading();
      uploadZipFiles(formData)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('上传成功');
            this.fileList = [];
            this.$emit('uploadSuccess');
          }
        })
        .finally(() => {
          this.$hideLoading();
          this.$emit('uploadLoading', false);
        });
    },
    uploadStart() {
      this.uploadZip();
      // this.$refs.upload.submit();
    },
    remove(file) {
      const i = this.fileList.indexOf(file);
      this.fileList.splice(i, 1);
    },
    handleChange(file, fileList) {
      let newArr = [];
      // 删除重复上传文件
      for (let i = 0; i < fileList.length; i++) {
        for (let j = 0; j < newArr.length; j++) {
          console.log(fileList[i].name, newArr[j].name, this.isRepeat);
          if (this.compareFiles(fileList[i], newArr[j])) {
            this.$message('请勿上传重复文件');
            this.isRepeat = false;
          }
        }
        if (this.isRepeat && this.activeTab == 1) {
          newArr.push(fileList[i]);
        } else {
          // fb 单个文件上传
          newArr = [fileList[i]];
        }
      }
      fileList = newArr;

      if (this.isRepeat && this.activeTab == 1) {
        // 验证file(fb不需要)
        let params = {
          zipName: file.name,
        };
        checkZipName(params).then((res) => {
          if (res.code != 0) {
            for (let i = 0; i < this.fileList.length; i++) {
              if (fileList[i].uid === file.uid) {
                this.$set(this.fileList[i], 'error', res.comment);
              }
            }
          }
        });
      }
      // 避免值在同一个事件队列中被一次性更新，这样会监听不到isRepeat的更改
      this.$nextTick(() => {
        this.isRepeat = true;
      });

      this.fileList = fileList;
    },
    compareFiles(file1, file2) {
      // 比较文件的一些属性，例如文件名、大小等
      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type;
    },
    downFile() {
      const url = this.fileUrlList[this.activeTab].url;
      window.open(url);
    },
    clear() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
  },
};
</script>
<style lang="scss" scoped>
.lh1 {
  line-height: 1.5;
}
</style>
