<template>
  <el-dialog
    title="草稿详情"
    :visible.sync="show"
    width="70%"
    show-close
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-table
      :data="list"
      height="500"
      style="width: 100%"
      border
    >
      <el-table-column
        prop="name"
        show-overflow-tooltip
        label="广告系列名称"
      >
      </el-table-column>
      <el-table-column
        prop="accountName"
        label="广告账户"
      >
        <template slot-scope="{ row }">
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.accountName"
              placement="top-start"
            >
              <p class="ellipsis-1">
                {{ row.accountName }}
              </p>
            </el-tooltip>
            <p style="font-size: 14px">{{ row.accountId }}</p>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="个号" width="200">
      </el-table-column> -->
      <el-table-column
        label="广告状态"
        width="120"
      >
        <template slot-scope="{ row }">
          <el-row :gutter="10">
            <el-col :span="18">
              <PointLabel
                :status="getStatus(row.uploadStatus)"
                :label="getLabel(row.uploadStatus)"
            /></el-col>
            <el-col
              :span="2"
              v-if="row.uploadStatus == 'UPLOAD_FAIL'"
            >
              <el-tooltip
                effect="dark"
                :content="row.errorMsg"
                placement="top"
              >
                <i class="el-icon-warning ml-1"></i> </el-tooltip
            ></el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="draftId"
        label="草稿ID"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
      >
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import PointLabel from './PointLabel.vue';

const StatusEnum = {
  DRAFT: {
    name: '草稿',
    type: 'info',
  },
  UPLOADED: {
    name: '已上传',
    type: 'success',
  },
  UPLOADING: {
    name: '上传中',
    type: 'warning',
  },
  UPLOAD_FAIL: {
    name: '上传失败',
    type: 'danger',
  },
};

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { PointLabel },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  methods: {
    getLabel(status) {
      return StatusEnum[status]?.name ?? '--';
    },
    getStatus(status) {
      return StatusEnum[status]?.type ?? 'info';
    },
  },
};
</script>
